"use client";

import useRudderStackAnalytics from "@/common/use-rudder-analytics";
import {useEffect} from "react";

export type RudderEvent = {
  [index: string]: string | number | boolean | RudderEvent | null | Date | (string | number | boolean | null | Date | RudderEvent)[] | undefined;
}

export default function FireRudderEvent({pageName, userID, event, eventPayload}: Readonly<{ pageName?: string, userID?: string, event?: string, eventPayload?: RudderEvent }>) {
  const analytics = useRudderStackAnalytics();

  useEffect(() => {
    pageName && analytics?.page(`page_${pageName}_viewed`);
  }, [pageName, analytics]);

  useEffect(() => {
    userID && analytics?.identify(userID);
  }, [pageName, userID, analytics]);

  useEffect(() => {
    event && analytics?.track(event, eventPayload);
  }, [pageName, event, analytics, eventPayload]);

  return <></>
}

export function fireRudderEvent({eventName, eventPayload}: Readonly<{ eventName: string, eventPayload: RudderEvent }>) {
  window.rudderanalytics?.track(eventName, eventPayload);
}
