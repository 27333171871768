import { useEffect, useState } from 'react';
import type { RudderAnalytics } from '@rudderstack/analytics-js';
import * as Sentry from "@sentry/nextjs";
import logger from "@/logger";

const useRudderStackAnalytics = (): RudderAnalytics | undefined => {
  const [analytics, setAnalytics] = useState<RudderAnalytics>();

  useEffect(() => {
    if (!analytics) {
      const initialize = async () => {
        const { RudderAnalytics } = await import('@rudderstack/analytics-js');
        const analyticsInstance = new RudderAnalytics();

        // @ts-ignore
        analyticsInstance.load(process.env.NEXT_PUBLIC_RUDDER_WRITE_KEY, process.env.NEXT_PUBLIC_RUDDER_DATA_PLANE_URL);

        analyticsInstance.ready(() => logger.debug('RudderStack initialized'));

        setAnalytics(analyticsInstance);
      };

      initialize().catch(e => Sentry.captureException(e));
    }
  }, [analytics]);

  return analytics;
};

export default useRudderStackAnalytics;
