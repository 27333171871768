import pino, { Logger } from "pino";

let log: Logger = pino({
  level: "info",
});

export const logger = log.child({ 
  service_name: "web"
})
export default logger;
